import { CheckOutlined, ClockCircleOutlined, FolderOpenOutlined, WarningOutlined, AlertOutlined } from '@ant-design/icons';
import { List, Popover, Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import { EMAIL_STATUS_BOUNCE, EMAIL_STATUS_CLICK, EMAIL_STATUS_DEFERRED, EMAIL_STATUS_DELIVERIED, EMAIL_STATUS_DROPPED, EMAIL_STATUS_ERROR, EMAIL_STATUS_OPEN, EMAIL_STATUS_SPAM } from '../constants/email';
import EmailStatusTitle from './EmailStatusTitle';

export default ({email, logs, cc}) => {
  const histories = (logs||[]).filter(({e}) => e === email);
  const { s } = histories[histories.length - 1]||{};

  let color, icon = <ClockCircleOutlined/>;

  switch(s) {
    case EMAIL_STATUS_ERROR:
    case EMAIL_STATUS_BOUNCE:
    case EMAIL_STATUS_DROPPED:
    case EMAIL_STATUS_SPAM:
      color = 'error';
      icon = <AlertOutlined/>;
      break;
    case EMAIL_STATUS_DEFERRED:
      color = 'warning';
      icon = <WarningOutlined/>;
      break;
    case EMAIL_STATUS_DELIVERIED:
      color = 'processing';
      icon = <CheckOutlined />;
      break;
    case EMAIL_STATUS_OPEN:
      icon = <FolderOpenOutlined />
      color = 'success';
      break;
    case EMAIL_STATUS_CLICK:
      icon = <FolderOpenOutlined />
      color = 'success';
      break;
  }

  return (
    <Popover content={
      <List size='small' dataSource={histories||[]} renderItem={({s, u}) => {
        return (
          <List.Item>
            <List.Item.Meta title={<EmailStatusTitle status={s}/>} description={moment.unix(u).format('HH:mm Do MMM YYYY')}/>
          </List.Item>
        )
      }}/>
    } title={email} overlayInnerStyle={{maxHeight: 400, overflowY: 'auto', minWidth: 300}}>
      <Tag icon={icon} color={color}>{cc?`cc:`:''}{email}</Tag>
    </Popover>
  );
}