import { getAuth, onAuthStateChanged, onIdTokenChanged } from '@firebase/auth';
import { doc, getFirestore, onSnapshot } from '@firebase/firestore';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { LANGUAGE_EN } from '../constants/language';
import { PERMISSION_DATA_SEARCH_CUSTOMERS, PERMISSION_DATA_SEARCH_ORDERS } from '../constants/permission';

const AuthenticationContext = createContext();

export function AuthenticationProvider({ children, onLocaleChange }) {
  const [session, setSession] = useState({});
  const [profile, setProfile] = useState(null);
  const { admin, realmId, mock, language } = { ...session, ...profile };

  useEffect(() => {
    if (language) {
      onLocaleChange(language);
    }
  }, [language]);

  useEffect(() => {
    const accountChanged = async (account) => {
      try {
        if (account) {
          const { uid, displayName, email, emailVerified } = account;
          const token = await account.getIdTokenResult();
          const { realmId, realmMaster, pendingSetup, mock, permissions, limitBusinesses, ebAccess, specialists } = token.claims;

          setSession({
            emailVerified,
            mock,
            tokenPermissions: permissions,
            limitBusinesses: (Array.isArray(limitBusinesses) && (limitBusinesses.length > 0)) ? limitBusinesses : null,
            ebAccess,
            email,
            admin: {
              id: uid,
              name: displayName || ''
            },
            ...(Array.isArray(specialists) && (specialists.length > 0) && { specialists }),
            ...(realmId && token.claims.roles ? {
              realmId,
              pendingSetup,
              realmMaster: realmMaster || false
            } : {
              realmId: false
            })
          });
        } else {
          setSession({ admin: false });
        }
      } catch (err) {
        console.error(err);
      }
    };

    const handler = onAuthStateChanged(getAuth(), accountChanged);
    const handler2 = onIdTokenChanged(getAuth(), accountChanged);

    return () => {
      handler();
      handler2();
    };
  }, []);

  useEffect(() => {
    setProfile(null);
    if (admin && realmId && !mock) {
      const handler = onSnapshot(doc(getFirestore(), `realms/${realmId}/admins/${admin.id}`), doc => {
        const { name, limitBusinesses, specialists, permissions, emailVerified, language } = doc.data() || {};

        setProfile({
          language,
          limitBusinesses: (Array.isArray(limitBusinesses) && (limitBusinesses.length > 0)) ? limitBusinesses : null,
          ...(Array.isArray(permissions) && { docPermissions: permissions }),
          ...(emailVerified && { emailVerified: true }),
          ...(Array.isArray(specialists) && (specialists.length > 0) && { specialists }),
          name
        });
      });
      return handler;
    }
  }, [admin?.id, realmId, mock]);

  const context = useMemo(() => {
    const { admin, name, email, ...others } = { ...session, ...profile };

    return {
      ...others,
      email,
      admin,
      ...(admin && name) && {
        admin: {
          ...admin,
          ...(name && { name })
        }
      }
    }
  }, [session, profile]);

  return (
    <AuthenticationContext.Provider value={context}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export const useAdmin = () => useContext(AuthenticationContext).admin;
export const useAdminEmail = () => useContext(AuthenticationContext).email;
export const useBusinessAgent = () => {
  const { specialists, admin } = useContext(AuthenticationContext);
  return specialists ? admin : null;
}
export const useLanguage = () => useContext(AuthenticationContext).language || LANGUAGE_EN;
export const useIsMockMode = () => useContext(AuthenticationContext).mock || false;
export const useIsEBAccess = () => useContext(AuthenticationContext).ebAccess;
export const useRealmId = () => useContext(AuthenticationContext).realmId;
export const useEamilVerified = () => useContext(AuthenticationContext).emailVerified;
export const useRealmMaster = () => useContext(AuthenticationContext).realmMaster;
export const usePermissions = () => {
  const { tokenPermissions, docPermissions } = useContext(AuthenticationContext);
  return docPermissions || tokenPermissions;
}
export const useLimitBusinsses = () => useContext(AuthenticationContext).limitBusinesses;
export const useIsLimited = () => {
  const realmMaster = useRealmMaster();
  const limits = useLimitBusinsses();

  return useCallback((businessCode) => {
    if (realmMaster) {
      return false;
    } else if (limits === undefined) {
      return true; // Loading
    } else if (!Array.isArray(limits) || (limits.length === 0)) {
      return false;
    } else {
      return limits.indexOf(businessCode) < 0;
    }
  }, [realmMaster, realmMaster ? null : limits]);
}
export const usePendingSetup = () => useContext(AuthenticationContext).pendingSetup;
export const usePermissionReady = () => Boolean(useContext(AuthenticationContext).docPermissions);

// Permission helpers
function check({ realmMaster, tokenPermissions, docPermissions }, checkKey) {
  // Skip limitation permission for master account
  if ([PERMISSION_DATA_SEARCH_ORDERS, PERMISSION_DATA_SEARCH_CUSTOMERS].indexOf(checkKey) >= 0) {
    if (realmMaster) return false; // Realm master have full permission
  }
  if (realmMaster) return true; // Realm master have full permission

  const permissions = docPermissions || tokenPermissions;
  if (!Array.isArray(permissions)) return false;

  return permissions.indexOf(checkKey) >= 0;
}

export const useAccess = (...keys) => {
  const context = useContext(AuthenticationContext);

  if (keys.length === 0) {
    return false;
  } else {
    return Boolean(keys.find(key => check(context, key)));
  }
} 