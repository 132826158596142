import { Modal } from 'antd';
import React, { useState } from 'react';
import { useOperation } from '../contexts/InstallationContext';
import OperationMonitor from './OperationMonitor';

export default ({ zIndex, title, visible, onCancel, onOk, onSuccess, confirmLoading, children, footer, okText, maskClosable, width, okButtonProps, style, onProgress }) => {
  const [operationRef, setOperationRef] = useState();
  const runOperation = useOperation();

  const onSubmit = () => {
    const data = onOk();

    if (data) {
      setOperationRef(runOperation(data));
    }
  };

  return (
    <Modal zIndex={zIndex} title={title} visible={visible} onCancel={onCancel} onOk={onSubmit} okText={okText} okButtonProps={okButtonProps}
      confirmLoading={Boolean(operationRef || confirmLoading)} destroyOnClose footer={footer} maskClosable={maskClosable}
      width={width} style={style}>
      {children}
      <OperationMonitor operationRef={operationRef} onComplete={successDoc => {
        setOperationRef(null);
        if (successDoc) {
          onSuccess(successDoc);
        }
      }} onProgress={onProgress} />
    </Modal>
  );
}