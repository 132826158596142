import { Modal } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ADMIN_OPERATION_SEND_VERIFICATION_EMAIL } from '../constants/admin';
import OperationButton from './OperationButton';

export default () => {
  const intl = useIntl();

  const onResend = () => {
    return {
      type: ADMIN_OPERATION_SEND_VERIFICATION_EMAIL
    };
  };

  return (
    <OperationButton size='small' onClick={onResend} onSuccess={successDoc => {
      Modal.success({
        title: intl.formatMessage({ defaultMessage: `Verification email has been sent to {address}, please check your inbox.` }, { address: successDoc.get('email') })
      });
    }}><FormattedMessage tagName='span' defaultMessage='Resend email' /></OperationButton>
  );
}