import { CheckOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { doc, getFirestore, onSnapshot } from '@firebase/firestore';
import { Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EMAIL_STATUS_BOUNCE, EMAIL_STATUS_CLICK, EMAIL_STATUS_DEFERRED, EMAIL_STATUS_DELIVERIED, EMAIL_STATUS_DROPPED, EMAIL_STATUS_ERROR, EMAIL_STATUS_OPEN, EMAIL_STATUS_SPAM } from '../constants/email';
import { useRealmId } from '../contexts/AuthenticationContext';
import EmailStatusTag from './EmailStatusTag';
import EmailStatusTitle from './EmailStatusTitle';

export default ({ emailId }) => {

  const realmId = useRealmId();
  const [email, setEmail] = useState(null);

  useEffect(() => {
    setEmail(null);
    if (realmId && emailId) {
      const handler = onSnapshot(doc(getFirestore(), `realms/${realmId}/emails/${emailId}`), setEmail);
      return handler;
    }
  }, [realmId, emailId]);

  let icon = <LoadingOutlined />, type = 'info';

  switch (email?.get('status')) {
    case EMAIL_STATUS_DELIVERIED:
      icon = <CheckOutlined />;
      break;
    case EMAIL_STATUS_OPEN:
    case EMAIL_STATUS_CLICK:
      icon = <CheckOutlined />;
      type = 'success';
      break;
    case EMAIL_STATUS_BOUNCE:
    case EMAIL_STATUS_DROPPED:
    case EMAIL_STATUS_DEFERRED:
    case EMAIL_STATUS_SPAM:
    case EMAIL_STATUS_ERROR:
      type = 'error';
      icon = <WarningOutlined />;
      break;
  }

  return <Alert type={type} banner message={email?.exists() ? <EmailStatusTitle status={email.get('status')} />
    : <FormattedMessage defaultMessage='Sending...' />} icon={icon} description={
      email?.exists() ? email.get('to')
        .map(address => <EmailStatusTag key={address} email={address} logs={email.get('logs')} />) : null
    } />
}