import { PAYMENT_ID_ALIPAY, PAYMENT_ID_CASH, PAYMENT_ID_CREDIT, PAYMENT_ID_GRABPAY, PAYMENT_ID_PAYNOW, PAYMENT_ID_PAYNOW_STRIPE, PAYMENT_ID_POINTS, PAYMENT_ID_STRIPE, PAYMENT_ID_WECHATPAY } from '../constants/payment';

export default (paymentId, paymentMethodTitles) => {
  switch(paymentId) {
    case PAYMENT_ID_STRIPE:
      return 'Credit card';
    case PAYMENT_ID_GRABPAY:
      return 'GrabPay';
    case PAYMENT_ID_WECHATPAY:
      return 'Wechat Pay';
    case PAYMENT_ID_ALIPAY:
      return 'Alipay';
    case PAYMENT_ID_CASH:
      return 'Cash';
    case PAYMENT_ID_CREDIT:
      return 'Advance credit';
    case PAYMENT_ID_POINTS:
      return 'Points';
    case PAYMENT_ID_PAYNOW:
    case PAYMENT_ID_PAYNOW_STRIPE:
      return 'PAYNOW';
    default:
      return (paymentMethodTitles&&paymentMethodTitles[paymentId])||null;
  }
}