export const PRICING_MODE_STANDARD = 'standard';
export const PRICING_MODE_PACKAGE = 'package';
export const PRICING_MODE_FLEXIBLE = 'flexible';

export const ORDER_CONFIRM_STAGE_CREATION = 'creation';
export const ORDER_CONFIRM_STAGE_PICKUP = 'pikcup';
export const ORDER_CONFIRM_STAGE_SERVICE_CENTER = 'service_center';
export const ORDER_CONFIRM_STAGE_DELIVERY = 'delivery';

export const PAYMENT_CAPTURE_ORDER_CREATION = 'creation';
export const PAYMENT_CAPTURE_PRICING_CONFIRMED = 'pricing_confirmed';
export const PAYMENT_CAPTURE_CUSTOMER_CONFIRMED = 'customer_confirmed';
export const PAYMENT_CAPTURE_PICKUP = 'pickup';
export const PAYMENT_CAPTURE_DELIVERY = 'delivery';
export const PAYMENT_CAPTURE_DELIVERY_READY = 'delivery_ready';

export const ORDER_RECONFIRM_ALL = 'all';
export const ORDER_RECONFIRM_PRIING_CHANGES = 'pricing_changes';