import { getAuth, signInWithCustomToken } from '@firebase/auth';
import queryString from 'query-string';
import React, { lazy, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useAdmin, usePendingSetup, useRealmId } from './contexts/AuthenticationContext';
import { PageProvider } from './contexts/PageContext';

const Dashboard = lazy(() => import('./Dashboard'));
const SignInPage = lazy(() => import('./SignInPage'));
const ForgotPasswordPage = lazy(() => import('./ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('./ResetPasswordPage'));
const SignUpAccountPage = lazy(() => import('./SignUpAccountPage'));
const SignUpRealmPage = lazy(() => import('./SignUpRealmPage'));
const SignUpBusinessPage = lazy(() => import('./SignUpBusinessPage'));
const VeriicationPage = lazy(() => import('./VerificationPage'));

export default () => {
  const admin = useAdmin();
  const realmId = useRealmId();
  const pendingSetup = usePendingSetup();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const { signintoken } = params;

  useEffect(() => {
    if (signintoken) {
      signInWithCustomToken(getAuth(), signintoken)
        .then(() => history.replace(`${location.pathname}`));
    }
  }, [signintoken]);

  if (realmId) {
    if (pendingSetup) {
      return (
        <Switch>
          <Route path='/sign-up/business'><SignUpBusinessPage /></Route>
          <Route path='/verifications/:verificationId' exact><VeriicationPage /></Route>
          <Redirect to='/sign-up/business' />
        </Switch>
      );
    } else {
      return (
        <PageProvider>
          <Switch>
            <Route path='/dashboard'><Dashboard /></Route>
            <Route path='/verifications/:verificationId' exact><VeriicationPage /></Route>
            <Redirect to='/dashboard' />
          </Switch>
        </PageProvider>
      );
    }
  } else if (admin) {
    return (
      <Switch>
        <Route path='/sign-up/location'><SignUpRealmPage /></Route>
        <Route path='/verifications/:verificationId' exact><VeriicationPage /></Route>
        <Redirect to='/sign-up/location' />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path='/sign-in'><SignInPage /></Route>
        <Route path='/password/forgot'><ForgotPasswordPage /></Route>
        <Route path='/password/reset'><ResetPasswordPage /></Route>
        <Route path='/sign-up/account'><SignUpAccountPage /></Route>
        <Route path='/verifications/:verificationId' exact><VeriicationPage /></Route>
        {(admin === false) && <Redirect to='/sign-in' />}
      </Switch>
    );
  }
}