import { Button, message, notification } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

const ServiceWorkerContext = React.createContext();

export function AppVersionProvider({children}) {
  const intl = useIntl();
  const [haveNewVersion, setHaveNewVersion] = useState(false);

  const onUpgrade = useCallback(() => {
    message.info(intl.formatMessage({defaultMessage: 'Updating...'}));
      
    const watchDog = setTimeout(() => {
      window.location.reload(true);
    }, 10000);

    navigator.serviceWorker.getRegistration()
      .then(registration => {
        const waitingWorker = registration&&registration.waiting;
        if (waitingWorker) {
          waitingWorker.onstatechange = () => {
            if (waitingWorker.state === 'activated') {
              clearTimeout(watchDog);
              window.location.reload(true);
            }
          };
          waitingWorker.postMessage({type: 'SKIP_WAITING'});
        } else {
          registration&&registration.update();
        }
      })
      .catch(() => clearTimeout(watchDog));
  }, []);

  // Service worker
  useEffect(() => {
    const onNotice = () => {
      setHaveNewVersion(true);

      notification.open({
        key: 'newversion',
        duration: null,
        message: intl.formatMessage({defaultMessage: 'New version available'}),
        description: 'A new version of EasyBiz Cloud console is ready for update',
        btn: <Button onClick={() => {
          notification.close('newversion');
          onUpgrade();
        }}>{intl.formatMessage({defaultMessage: 'Update'})}</Button>
      });
    };

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration()
        .then(registration => {
          if (registration&&registration.waiting) {
            onNotice();
          }
        });
    }

    const onUpdate = (e) => {
      if (e.data.updateAvailable) {
        onNotice();
      }
    };

    window.addEventListener('message', onUpdate);

    return () => window.removeEventListener('message', onUpdate);
  }, []);

  const context = useMemo(() => {
    return {
      onUpgrade,
      haveNewVersion
    };
  }, [haveNewVersion]);

  return (
    <ServiceWorkerContext.Provider value={context}>
      {children}
    </ServiceWorkerContext.Provider>
  );
}

export const useHaveNewVersion = () => useContext(ServiceWorkerContext).haveNewVersion;
export const useConsoleUpgrade = () => useContext(ServiceWorkerContext).onUpgrade;