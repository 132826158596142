import React from 'react';
import { Alert, Button } from "antd";
import CenterContainer from "./CenterContainer";
import { FormattedMessage } from 'react-intl';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorMsg: error&&error.message };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className='padding'>
          <CenterContainer>
            <Alert banner showIcon type='error' message={<FormattedMessage defaultMessage='Error'/>}
              description={this.state.errorMsg} action={<Button onClick={() => {
                window.location.reload(true);
              }}><FormattedMessage defaultMessage='Reload page'/></Button>} />
          </CenterContainer>
        </div>
      );
    }

    return this.props.children; 
  }
}