import React, { createContext, useContext, useEffect, useState } from 'react';

const NetworkStatusContext = createContext();

export function NetworkStatusProvider({children}) {
  const [networkStatus, setNetWorkStatus] = useState(navigator.onLine?true:false);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setNetWorkStatus(navigator.onLine?true:false);
    };

    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  return (
    <NetworkStatusContext.Provider value={networkStatus}>
      {children}
    </NetworkStatusContext.Provider>
  );
}

export const useIsOnline = () => useContext(NetworkStatusContext);