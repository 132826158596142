import { initializeApp } from '@firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';
import { ConfigProvider } from 'antd';
import ms_MY from 'antd/lib/locale/ms_MY';
import zh_CN from 'antd/lib/locale/zh_CN';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router } from "react-router-dom";
import './App.less';
import { AuthenticationProvider } from './contexts/AuthenticationContext';
import { ImageGalleryProvider } from './contexts/ImageGalleryContext';
import { InstallationProvider } from './contexts/InstallationContext';
import { IntercomProvider } from './contexts/IntercomContext';
import { NetworkStatusProvider } from './contexts/NetworkStatusContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { RealmProvider } from './contexts/RealmContext';
import { AppVersionProvider } from './contexts/ServiceWorkerContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import Pages from './Pages';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './widgets/ErrorBoundary';
import PageLoading from './widgets/PageLoading';

const app = initializeApp({
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId
});

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_siteKey),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

function Root() {
  const [locale, setLocale] = useState(navigator?.language ? navigator.language.split('-')[0] : 'en');
  const [messages, setMessages] = useState({});

  useEffect(() => {
    switch (locale) {
      case 'zh':
      case 'zh-CN':
      case 'zh-TW':
        import('./lang/zh-CN.json').then(setMessages);
        break;
      default:
        import('./lang/en.json').then(setMessages);
    }
  }, [locale]);

  const antLocale = useMemo(() => {
    switch (locale) {
      case 'zh':
      case 'zh-CN':
        return zh_CN;
      case 'ms': return ms_MY;
      default: return null;
    }
  }, [locale]);

  return (
    <IntlProvider messages={messages} locale={locale}>
      <ConfigProvider locale={antLocale}>
        <ErrorBoundary>
          <NetworkStatusProvider>
            <AppVersionProvider>
              <AuthenticationProvider onLocaleChange={setLocale}>
                <RealmProvider>
                  <NotificationProvider>
                    <InstallationProvider>
                      <ImageGalleryProvider>
                        <SubscriptionProvider>
                          <IntercomProvider>
                            <Suspense fallback={<PageLoading />}>
                              <Router>
                                <Pages />
                              </Router>
                            </Suspense>
                          </IntercomProvider>
                        </SubscriptionProvider>
                      </ImageGalleryProvider>
                    </InstallationProvider>
                  </NotificationProvider>
                </RealmProvider>
              </AuthenticationProvider>
            </AppVersionProvider>
          </NetworkStatusProvider>
        </ErrorBoundary>
      </ConfigProvider>
    </IntlProvider>
  );
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: () => window.postMessage({ updateAvailable: true }, '*')
});