import { getAuth, signOut } from '@firebase/auth';
import { Alert, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { EXPIRE_CODE_ACCOUNT_DELETED, EXPIRE_CODE_DEVICE_BLOCKED, EXPIRE_CODE_LOGGED_OUT, EXPIRE_CODE_MOCK_TIMEOUT, EXPIRE_CODE_NOT_WHITELISTED, EXPIRE_CODE_PASSWORD_CHANGED, EXPIRE_CODE_SINGIN_OTHER_DEVICE } from '../constants/account';
import { useAdmin } from '../contexts/AuthenticationContext';
import { useRegisteredDevice } from '../contexts/InstallationContext';

export default () => {

  const device = useRegisteredDevice();
  const admin = useAdmin();
  const intl = useIntl();
  const [expireCode, setExpireCode] = useState();

  useEffect(() => {
    if (device?.get('expireCode') && admin) {
      setExpireCode(device?.get('expireCode'));
      signOut(getAuth());
    }
  }, [device?.get('expireCode')]);

  const expireMessage = useMemo(() => {
    switch (expireCode) {
      case EXPIRE_CODE_SINGIN_OTHER_DEVICE:
        return intl.formatMessage({ defaultMessage: 'Your account has been logged in another device, <b>if unauthorized, please reset your password immediately.</b>' },
          { b: t => <b>{t}</b> });
      case EXPIRE_CODE_ACCOUNT_DELETED:
        return intl.formatMessage({ defaultMessage: 'Your account has been deleted' });
      case EXPIRE_CODE_PASSWORD_CHANGED:
        return intl.formatMessage({ defaultMessage: 'Your account password has been changed, please sign in again with your new password' });
      case EXPIRE_CODE_NOT_WHITELISTED:
        return intl.formatMessage({ defaultMessage: 'Your device is not whitelisted to access the system, please contact your admin to whitelist your device, <b>your device ID is {deviceId}</b>' },
          { b: t => <b>{t}</b>, deviceId: device?.id });
      case EXPIRE_CODE_LOGGED_OUT:
        return intl.formatMessage({ defaultMessage: 'Your account has been logged out' });
      case EXPIRE_CODE_DEVICE_BLOCKED:
        return intl.formatMessage({ defaultMessage: 'Your device has been blocked' });
      case EXPIRE_CODE_MOCK_TIMEOUT:
        return intl.formatMessage({ defaultMessage: 'Mock sign in expired' });
    }
  }, [expireCode]);

  return (
    <Modal title={intl.formatMessage({ defaultMessage: 'Log in session expired' })}
      visible={Boolean(expireCode)} cancelButtonProps={{ hidden: true }} onOk={() => setExpireCode(null)}>
      <Alert banner type='info' message={expireMessage} />
    </Modal>
  );
}