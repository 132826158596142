export const SUBSCRIPTION_STATUS_TRIALING = 'trialing';
export const SUBSCRIPTION_STATUS_ACTIVE = 'active';
export const SUBSCRIPTION_STATUS_PAST_DUE = 'past_due';
export const SUBSCRIPTION_STATUS_UNPAID = 'unpaid';
export const SUBSCRIPTION_STATUS_CANCELLED = 'canceled';
export const SUBSCRIPTION_STATUS_INCOMPLETE = 'incomplete';
export const SUBSCRIPTION_STATUS_INCOMPLETE_EXPIRED = 'incomplete_expired';

export const SUBSCRIPTION_OPERATION_EDIT_PAYMENT_METHOD = 'edit_payment_method';
export const SUBSCRIPTION_OPERATION_EDIT_BILLING_COMPANY = 'edit_billing_company';
export const SUBSCRIPTION_OPERATION_PREVIEW = 'preview';
export const SUBSCRIPTION_OPERATION_NEW_SUBSCRIPTION = 'new_subscription';
export const SUBSCRIPTION_OPERATION_STOP_SUBSCRIPTION = 'stop_subscription';
export const SUBSCRIPTION_OPERATION_CANCEL_SUBSCRIPTION = 'cancel_subscription';
export const SUBSCRIPTION_OPERATION_SUBSCRIBE = 'subscribe';
export const SUBSCRIPTION_OPERATION_PREVIEW_INVOICE = 'preview_invoice';

export const INVOICE_STATUS_OPEN = 'open';