export const PAYMENT_STATUS_PENDING = 'pending';
export const PAYMENT_STATUS_SUCCEED = 'succeed';
export const PAYMENT_STATUS_FAILED = 'failed';
export const PAYMENT_STATUS_CANCELLED = 'cancelled';

export const PAYMENT_ID_CASH = 'CASH';
export const PAYMENT_ID_PAYNOW = 'PAYNOW';
export const PAYMENT_ID_PAYNOW_STRIPE = 'PAYNOW_STRIPE';
export const PAYMENT_ID_CREDIT = 'CREDIT';
export const PAYMENT_ID_POINTS = 'POINTS';
export const PAYMENT_ID_STRIPE = 'STRIPE';
export const PAYMENT_ID_GRABPAY = 'GRABPAY';
export const PAYMENT_ID_WECHATPAY = 'WECHATPAY';
export const PAYMENT_ID_ALIPAY = 'ALIPAY';