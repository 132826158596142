import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EMAIL_STATUS_BOUNCE, EMAIL_STATUS_CLICK, EMAIL_STATUS_DEFERRED, EMAIL_STATUS_DELIVERIED, EMAIL_STATUS_DROPPED, EMAIL_STATUS_ERROR, EMAIL_STATUS_OPEN, EMAIL_STATUS_PROCESSED, EMAIL_STATUS_SPAM } from '../constants/email';

export default ({status}) => {
    switch(status) {
      case EMAIL_STATUS_ERROR: return <FormattedMessage defaultMessage='Error' />;
      // TODO: REMOVE
      case 'sent': return <FormattedMessage defaultMessage='Procesed' />;
      case EMAIL_STATUS_PROCESSED: return <FormattedMessage defaultMessage='Procesed' />;
      case EMAIL_STATUS_DROPPED: return <FormattedMessage defaultMessage='Dropped' />;
      case EMAIL_STATUS_DEFERRED: return <FormattedMessage defaultMessage='Deferred' />;
      case EMAIL_STATUS_BOUNCE: return <FormattedMessage defaultMessage='Bounce' />;
      case EMAIL_STATUS_SPAM: return <FormattedMessage defaultMessage='Spam' />;
      case EMAIL_STATUS_DELIVERIED: return <FormattedMessage defaultMessage='Delivered' />;
      case EMAIL_STATUS_OPEN: return <FormattedMessage defaultMessage='Open' />;
      case EMAIL_STATUS_CLICK: return <FormattedMessage defaultMessage='Click' />;
      default:
        return status||null;
    }
}