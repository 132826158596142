import { onSnapshot } from '@firebase/firestore';
import { message } from 'antd';
import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { OPERATION_STATUS_FAILED, OPERATION_STATUS_SUCCEED } from '../constants/operation';
import ErrorDisplay from './ErrorDisplay';

export default ({operationRef, onComplete, onProgress}) => {
  useEffect(() => {
    if (operationRef) {
      const handler = onSnapshot(operationRef, result => {
        if (result.get('status') === OPERATION_STATUS_SUCCEED) {
          if (result.get('info')) {
            message.info(result.get('info'));
          }

          onComplete(result);
        } else if (result.get('status') === OPERATION_STATUS_FAILED) {
          if (result.get('errorCode')) {
            message.error(
              <IntlProvider messages={null} defaultLocale="en">
                <ErrorDisplay errorCode={result.get('errorCode')} errorParams={result.get('errorParams')}/>
              </IntlProvider>
            );
          } else {
            message.error(result.get('error'));
          }
          
          onComplete(null, result);
        } else if (onProgress) {
          onProgress(result);
        }
      });
      return handler;
    }
  }, [operationRef]);

  return null;
}