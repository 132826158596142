import React from 'react';
import { Row, Col } from 'antd';

export default ({className, children, compact, style}) => {
  return (
    <Row type='flex' align='center' style={style} className={className}>
      <Col xs={compact?23:24} sm={compact?22:23} md={compact?18:22} lg={compact?14:21} xl={compact?10:20} xxl={compact?8:19}>
        {children}
      </Col>
    </Row>
  );
}