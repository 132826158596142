import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ERROR_CODE_ADDRESS_NOT_IN_SERVICE_ZONE, ERROR_CODE_BOOKING_TIME_EXPIRED, ERROR_CODE_DUPLICA_ZONE_TIME_SETTING, ERROR_CODE_EMAIL_ALREADY_VERIFIED, ERROR_CODE_EMAIL_BINDED_WITH_OTHER_ACCOUNT, ERROR_CODE_PHONE_BINDED_WITH_OTHER_ACCOUNT, ERROR_CODE_RESCHEDULE_BLOCKED,
  ERROR_CODE_RESCHEDULE_LIMITED, ERROR_CODE_SCHEDULE_NO_PICKUP, ERROR_CODE_SMS_DAILY_LIMIT_REACH, ERROR_CODE_SMS_VERIFICAITON_EXPIRED, ERROR_CODE_SMS_VERIFICAITON_FAILED, ERROR_CODE_SMS_VERIFICAITON_FAILED_RETRY } from '../constants/error';
import moment from 'moment';

export default function ErrorDisplay ({errorCode, error, errorParams}) {
  const { hour, retryLeft, phoneNumber, email, zoneId, weekday } = errorParams||{};

  switch(errorCode||error) {
    case ERROR_CODE_ADDRESS_NOT_IN_SERVICE_ZONE:
      return <FormattedMessage defaultMessage={`Sorry, our service haven't cover your area yet.`} />;
    case ERROR_CODE_RESCHEDULE_BLOCKED:
      return <FormattedMessage defaultMessage={`Can not reschedule.`} />;
    case ERROR_CODE_RESCHEDULE_LIMITED:
      if (typeof hour === 'number') {
        return <FormattedMessage defaultMessage='Sorry, you can only do reschedule {count} hours before the current schedule.' values={{count: hour}}/>
      } else {
        return <FormattedMessage defaultMessage={`Can not reschedule.`} />;
      }
    case ERROR_CODE_SCHEDULE_NO_PICKUP:
      return <FormattedMessage defaultMessage={`Pick-up booking not selected or already expired, please select your pick-up booking again.`} />;
    case ERROR_CODE_BOOKING_TIME_EXPIRED:
      return <FormattedMessage defaultMessage={`Time slot already expired, please select again.`} />;
    case ERROR_CODE_EMAIL_ALREADY_VERIFIED:
      return <FormattedMessage defaultMessage={`Your email address has already been verified.`} />;
    case ERROR_CODE_SMS_VERIFICAITON_EXPIRED:
      return <FormattedMessage defaultMessage={`Verification OTP already expired, please send again.`} />;
    case ERROR_CODE_SMS_VERIFICAITON_FAILED_RETRY:
      return <FormattedMessage defaultMessage={`Wrong OTP, please try again ({count} retry left).`} values={{count: retryLeft}} />;
    case ERROR_CODE_SMS_VERIFICAITON_FAILED:
      return <FormattedMessage defaultMessage={`Verification failed, please request to send another OTP.`} />;
    case ERROR_CODE_SMS_DAILY_LIMIT_REACH:
      return <FormattedMessage defaultMessage={`You have reached the maximum limit to request new OTP per day, please try on another day.`} />;
    case ERROR_CODE_PHONE_BINDED_WITH_OTHER_ACCOUNT:
      return <FormattedMessage defaultMessage={`{phoneNumber} already been registered.`} values={{phoneNumber}} />;
    case ERROR_CODE_EMAIL_BINDED_WITH_OTHER_ACCOUNT:
      return <FormattedMessage defaultMessage={`{email} already been registered.`} values={{email}} />;
    case ERROR_CODE_DUPLICA_ZONE_TIME_SETTING:
      if (typeof zoneId === 'string') {
        return <FormattedMessage defaultMessage='You already have settings <b>with Zone {zoneId} on {weekday}</b>, you can edit your existing setting or remove {zoneId} from your existing setting then add new setting again, each zone can only have one setting on {weekday}.'
          values={{weekday: moment().day(weekday).format('dddd'), zoneId, b: t => <b>{t}</b>}} />;
      } else {
        return <FormattedMessage defaultMessage='You already have <b>default settings on {weekday}</b>, you can edit your existing setting or add new settings by zone.'
          values={{weekday: moment().day(weekday).format('dddd'), b: t => <b>{t}</b>}} />;
      }
    default:
      if (`${error}`.includes("Unable to claim interface.")) {
        return <FormattedMessage defaultMessage='It looks like you have another page connecting the printer right now, printer can only be connect with one page at a time, please close the other page and try again.' />;
      } else {
        return <FormattedMessage defaultMessage='Unknown error ({code})' values={{code: errorCode||error}}/>;
      }
  }
}