import React, { useContext, useMemo, useState } from 'react';
import ImageGallery from '../widgets/ImageGallery';

const ImageGalleryContext = React.createContext();

export function ImageGalleryProvider({children}) {
  const [galleryData, setGalleryData] = useState();

  const context = useMemo(() => {
    return {
      setPhotos: ({photos, viewIndex, orderId, taskId}) => setGalleryData({
        photos: (photos||[]).map(photo => {
          if (typeof photo === 'string') {
            return {
              url: photo
            };
          } else if (typeof photo?.data === 'function') {
            return {
              id: photo.id,
              url: photo.get('dataUrl'),
              title: `${photo.get('createDate.date')} ${photo.get('createDate.time')} ${photo.get('creator.name')||photo.get('operator.name')||''}`
            }
          } else {
            return photo;
          }
        }),
        viewIndex,
        orderId,
        taskId
      })
    }
  }, []);

  return (
    <ImageGalleryContext.Provider value={context}>
      {children}
      <ImageGallery {...galleryData} onClose={() => setGalleryData(null)}
        onDeleted={photoId => {
          const photos = (galleryData.photos||[]).filter(photo => photo.id !== photoId);

          if (photos.length > 0) {
            setGalleryData({...galleryData, photos});
          } else {
            setGalleryData(null);
          }
        }}/>
    </ImageGalleryContext.Provider>
  )
}

export const useViewPhotos = () => useContext(ImageGalleryContext).setPhotos

