export const EASYBIZ_PRODUCT_ID_POS = 'POS';
export const EASYBIZ_PRODUCT_ID_ONLINE = 'ONLINE';
export const EASYBIZ_PRODUCT_ID_WORKFLOW = 'WORKFLOW';
export const EASYBIZ_PRODUCT_ID_PLANT = 'FACTORY';

export const PRICE_STATUS_PUBLIC = 'public';
export const PRICE_STATUS_PRIVATE = 'private';

export const CLIENT_WEB = 'web';
export const CLIENT_POS = 'pos';
export const CLIENT_IOS = 'ios';
export const CLIENT_ANDROID = 'android';
export const CLIENT_MOBILE = 'mobile';
export const CLIENT_CONSOLE = 'console';

export const PLATFORM_OPERTION_PREVIEW_REALM = 'preview realm';
export const PLATFORM_OPERTION_SETUP_REALM = 'setup realm';
export const PLATFORM_OPERTION_SETUP_BUSINESS = 'setup business';
export const PLATFORM_OPERTION_FORGOT_PASSWORD = 'forgot password';
export const PLATFORM_OPERTION_RESET_PASSWORD = 'reset password';
export const PLATFORM_OPERTION_DELETE_ACCOUNT = 'delete account';
export const PLATFORM_OPERTION_VERIFY_EMAIL = 'verify email';