export const ORDER_FLOW_ORDER_NO_AUTO = 'auto';
export const ORDER_FLOW_ORDER_NO_MANUAL = 'manual';
export const ORDER_FLOW_CUSTOMER_NAME = 'name';
export const ORDER_FLOW_CUSTOMER_CONTACT = 'contact';
export const ORDER_FLOW_CUSTOMER_ADDRESS = 'address';

export const ORDER_FLOW_END_CUSTOMER = 'end_customer';
export const ORDER_FLOW_ORDER_NO = 'order_no';
export const ORDER_FLOW_TRACKING_NO = 'tracking_no';
export const ORDER_FLOW_TOTAL_PCS = 'total_pcs';
export const ORDER_FLOW_TOTAL_AMOUNT = 'total_amount';
export const ORDER_FLOW_RECEIVING_DATE = 'receiving_date';
export const ORDER_FLOW_DELIVERY_DATE = 'delivery_date';

export const ORDER_FLOW_PAYMENT = 'payment';
export const ORDER_FLOW_TAKE_PHOTO = 'photo';
export const ORDER_FLOW_CUSTOMER_SIGNATURE = 'signature';

export const ORDER_FLOWS = [
  ORDER_FLOW_END_CUSTOMER,
  ORDER_FLOW_ORDER_NO,
  ORDER_FLOW_TRACKING_NO,
  ORDER_FLOW_TOTAL_PCS,
  ORDER_FLOW_TOTAL_AMOUNT,
  ORDER_FLOW_RECEIVING_DATE,
  ORDER_FLOW_DELIVERY_DATE,
  ORDER_FLOW_PAYMENT,
  ORDER_FLOW_TAKE_PHOTO,
  ORDER_FLOW_CUSTOMER_SIGNATURE
];

export const BILLING_ROW_BY_DATE = 'byDate';
export const BILLING_ROW_BY_ORDER = 'byOrder';

export const BILLING_SORTING_BY_DATE = 'byDate';
export const BILLING_SORTING_BY_ORDER_NO = 'byOrderNo';
export const BILLING_SORTING_BY_ORDER_NO_CONTINUES = 'byContinuesOrderNo';

export const BILLING_COLUMN_DATE = 'date';
export const BILLING_COLUMN_ORDER_NO = 'orderNo';
export const BILLING_COLUMN_TRACKING_NO = 'trackingNo';
export const BILLING_COLUMN_TOTAL_AMOUNT = 'totalAmount';
export const BILLING_COLUMN_TOTAL_QUANTITY = 'totalQty';
export const BILLING_COLUMN_CUSTOMER_NAME = 'customerName';
export const BILLING_COLUMN_CUSTOMER_CONTACT = 'customerContact';
export const BILLING_COLUMN_CUSTOMER_ADDRESS = 'customerAddress';
export const BILLING_COLUMN_ITEM_SUMMARY = 'summary';
export const BILLING_COLUMN_ITEM_BREAKDOWNS = 'breakdowns';
export const BILLING_COLUMN_SURCHARGE = 'surcharge';

export const BILLING_COLUMNS = [
  BILLING_COLUMN_DATE,
  BILLING_COLUMN_ORDER_NO,
  BILLING_COLUMN_TRACKING_NO,
  BILLING_COLUMN_TOTAL_AMOUNT,
  BILLING_COLUMN_TOTAL_QUANTITY,
  BILLING_COLUMN_CUSTOMER_NAME,
  BILLING_COLUMN_CUSTOMER_CONTACT,
  BILLING_COLUMN_CUSTOMER_ADDRESS,
  BILLING_COLUMN_ITEM_SUMMARY,
  BILLING_COLUMN_ITEM_BREAKDOWNS,
  BILLING_COLUMN_SURCHARGE
];

export const INVOICE_OPERATION_TYPE_CREATE = 'create';
export const INVOICE_OPERATION_TYPE_VOID = 'void';
export const INVOICE_OPERATION_TYPE_PAID = 'paid';

export const BILLING_ANCHOR_CREATE_DATE = 'create';
export const BILLING_ANCHOR_DELIVERY_DATE = 'delivery';

export const ORDER_SOURCE_FACTORY = 'factory';
export const BUSINESS_ID_FACTORY = 'FACTORY';
export const SERVICE_ID_FACTORY = 'FACTORY';

export const RUNNING_NO_FORMAT_GLOBAL_YYMMDDXXX = 'GLOBAL-YYMMDDXXX';

export const RECEIPT_PLACEHOLDER_TOTAL_AMOUNT = 'netSales';
export const RECEIPT_PLACEHOLDER_OPERATOR = 'operator';

export const BREAKDOWN_SUMMARY_QUANTITY = 'quantity';
export const BREAKDOWN_SUMMARY_UNIT_PRICE = 'unitPrice';
export const BREAKDOWN_SUMMARY_TOTAL_AMOUNT = 'totalAmount';