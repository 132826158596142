import { collection, doc, getFirestore } from '@firebase/firestore';
import { getMessaging, getToken, isSupported, onMessage } from '@firebase/messaging';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useAdmin } from './AuthenticationContext';

const NotificationContext = React.createContext();

export function NotificationProvider({ children }) {
  const admin = useAdmin();
  const [webToken, setWebToken] = useState();
  const [pushMessage, setPushMessage] = useState();

  // Push notification
  useEffect(() => {
    if (admin && isSupported()) {
      getToken(getMessaging(), {
        vapidKey: process.env.REACT_APP_vapidKey
      }).then(setWebToken).catch((err) => {
        console.log('Get token error', err);
      });

      onMessage(getMessaging(), ({ notification, data = {} }) => {
        if (notification) {
          if (data && data.conversationId) {
            setPushMessage({
              ...data,
              ...notification
            });
          } else {
            // For order & task changes
            const messageId = doc(collection(getFirestore(), `notifications`)).id;
            const message = {
              key: messageId,
              message: notification.title,
              description: notification.body,
              onClick: () => {
                notification.close(messageId);
              }
            };

            if (typeof notification[data?.type] === 'function') {
              notification[data.type](message);
            } else {
              notification.open(message);
            }
          }
        }
      });
    }
  }, [admin?.id]);

  const context = useMemo(() => {
    return {
      pushMessage,
      webToken
    };
  }, [pushMessage, webToken]);

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
}

export const usePushToken = () => useContext(NotificationContext).webToken;
export const usePushMessage = () => useContext(NotificationContext).pushMessage;