import { PRICING_MODE_FLEXIBLE, PRICING_MODE_PACKAGE, PRICING_MODE_STANDARD } from "./pricing";

export const BUSINESS_TYPE_SHOP = 'shop';
export const BUSINESS_TYPE_ONLINE = 'online';
export const BUSINESS_TYPE_FACTORY = 'factory';

export const BUSINESS_SECTOR_LAUNDRY = 'LAUNDRY';
export const BUSINESS_SECTOR_ALTERATION = 'ALTERATION';
export const BUSINESS_SECTOR_BAG_SERVICE = 'BAG SERVICE';
export const BUSINESS_SECTOR_AIRCON_SERVICE = 'AIRCON SERVICE';
export const BUSINESS_SECTOR_CLEANING = 'HOME CLEANING';
export const BUSINESS_SECTOR_HAIR_SALON = 'HAIR SALON';
export const BUSINESS_SECTOR_MASSAGE = 'MASSAGE';
export const BUSINESS_SECTOR_BEAUTY_SHOP = 'BEAUTY SHOP';
export const BUSINESS_SECTOR_YOGA_FITNESS = 'YOGA FITNESS';
export const BUSINESS_SECTOR_PEST_CONTROL = 'PEST CONTROL';
export const BUSINESS_SECTOR_HANDYMAN = 'HANDYMAN';

export const BUSINESS_SECTORS = [
  BUSINESS_SECTOR_LAUNDRY,
  BUSINESS_SECTOR_ALTERATION,
  BUSINESS_SECTOR_BAG_SERVICE,
  BUSINESS_SECTOR_AIRCON_SERVICE,
  BUSINESS_SECTOR_CLEANING,
  BUSINESS_SECTOR_PEST_CONTROL,
  BUSINESS_SECTOR_HANDYMAN,
  // BUSINESS_SECTOR_HAIR_SALON,
  // BUSINESS_SECTOR_MASSAGE,
  // BUSINESS_SECTOR_BEAUTY_SHOP,
  // BUSINESS_SECTOR_YOGA_FITNESS
];

export function isGuidePriceBusiness(sector) {
  return [BUSINESS_SECTOR_BAG_SERVICE, BUSINESS_SECTOR_ALTERATION, BUSINESS_SECTOR_HANDYMAN, BUSINESS_SECTOR_PEST_CONTROL].indexOf(sector) >= 0;
}

export function isPUDBusiness(businessSector) {
  return [
    BUSINESS_SECTOR_LAUNDRY,
    BUSINESS_SECTOR_ALTERATION,
    BUSINESS_SECTOR_BAG_SERVICE
  ].indexOf(businessSector) >= 0;
}

export function isDeliveryBusiness(sector) {
  return [
    
  ].indexOf(sector) >= 0;
}

export function isPickupBusiness(sector) {
  return [
    BUSINESS_SECTOR_LAUNDRY,
    BUSINESS_SECTOR_ALTERATION,
    BUSINESS_SECTOR_BAG_SERVICE
  ].indexOf(sector) >= 0;
}

export function isHomeServiceBusiness(sector) {
  return [
    BUSINESS_SECTOR_AIRCON_SERVICE,
    BUSINESS_SECTOR_CLEANING,
    BUSINESS_SECTOR_PEST_CONTROL,
    BUSINESS_SECTOR_HANDYMAN
  ].indexOf(sector) >= 0;
}

export function isDoorToDoorBusiness(businessSector) {
  return [
    BUSINESS_SECTOR_AIRCON_SERVICE,
    BUSINESS_SECTOR_PEST_CONTROL,
    BUSINESS_SECTOR_HANDYMAN,
    BUSINESS_SECTOR_CLEANING
  ].indexOf(businessSector) >= 0;
}

export function isOnlineBusiness(sector) {
  return [
    BUSINESS_SECTOR_LAUNDRY,
    BUSINESS_SECTOR_ALTERATION,
    BUSINESS_SECTOR_BAG_SERVICE,
    BUSINESS_SECTOR_CLEANING,
    BUSINESS_SECTOR_AIRCON_SERVICE,
    BUSINESS_SECTOR_PEST_CONTROL,
    BUSINESS_SECTOR_HANDYMAN
  ].indexOf(sector) >= 0;
}

export function isStoreBusiness(sector) {
  return [
    BUSINESS_SECTOR_LAUNDRY,
    BUSINESS_SECTOR_ALTERATION,
    BUSINESS_SECTOR_BAG_SERVICE,
    BUSINESS_SECTOR_BEAUTY_SHOP,
    BUSINESS_SECTOR_HAIR_SALON,
    BUSINESS_SECTOR_MASSAGE,
    BUSINESS_SECTOR_YOGA_FITNESS
  ].indexOf(sector) >= 0;
}

export function isRecurringBusiness(sector) {
  return [
    BUSINESS_SECTOR_AIRCON_SERVICE,
    BUSINESS_SECTOR_CLEANING,
    BUSINESS_SECTOR_PEST_CONTROL
  ].indexOf(sector) >= 0;
}

export function isFactoryBusiness(sector) {
  return [
    BUSINESS_SECTOR_LAUNDRY,
    BUSINESS_SECTOR_ALTERATION,
    BUSINESS_SECTOR_BAG_SERVICE
  ].indexOf(sector) >= 0;
}

export function isLabelingBusiness(sector) {
  return [
    BUSINESS_SECTOR_LAUNDRY,
    BUSINESS_SECTOR_ALTERATION,
    BUSINESS_SECTOR_BAG_SERVICE
  ].indexOf(sector) >= 0;
}

export function isBusinessSupportSubtype(sector) {
  return getBusinessPricingMode(sector) === PRICING_MODE_STANDARD;
}

export function getBusinessPricingMode(sector) {
  switch(sector) {
    case BUSINESS_SECTOR_ALTERATION:
    case BUSINESS_SECTOR_BAG_SERVICE:
    case BUSINESS_SECTOR_PEST_CONTROL:
    case BUSINESS_SECTOR_HANDYMAN:
    case BUSINESS_SECTOR_MASSAGE:
    case BUSINESS_SECTOR_BEAUTY_SHOP:
    case BUSINESS_SECTOR_YOGA_FITNESS:
      return PRICING_MODE_FLEXIBLE;
    case BUSINESS_SECTOR_AIRCON_SERVICE:
    case BUSINESS_SECTOR_CLEANING:
      return PRICING_MODE_PACKAGE;
    default:
      return PRICING_MODE_STANDARD;
  }
}