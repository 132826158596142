import { message } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ADMIN_OPERATION_DELETE_PHOTO } from '../constants/admin';
import OperationButton from './OperationButton';

export default ({ orderId, taskId, photoId, onDeleted }) => {
  const intl = useIntl();

  return (
    <OperationButton type={'danger'} confirmTitle={<FormattedMessage defaultMessage='Delete photo?' />} onClick={() => {
      return {
        type: ADMIN_OPERATION_DELETE_PHOTO,
        photoId,
        ...(orderId && { orderId }),
        ...(taskId && { taskId })
      };
    }} onSuccess={() => {
      message.success(intl.formatMessage({ defaultMessage: 'Deleted' }));
      onDeleted && onDeleted(photoId);
    }}><FormattedMessage tagName={'span'} defaultMessage={'Delete'} /></OperationButton>
  );
}