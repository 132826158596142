export const ERROR_CODE_RESCHEDULE_BLOCKED = 'reschedule_blocked';
export const ERROR_CODE_RESCHEDULE_LIMITED = 'reschedule_limited';
export const ERROR_CODE_SCHEDULE_NO_PICKUP = 'schedule_no_pickup';
export const ERROR_CODE_ADDRESS_NOT_IN_SERVICE_ZONE = 'address_not_in_service_zone';
export const ERROR_CODE_BOOK_SLOT_FULL = 'book_slot_full';
export const ERROR_CODE_BOOKING_TIME_EXPIRED = 'booking_time_expired';

export const ERROR_CODE_STORE_APP_LIMITED = 'store_app_limited';
export const ERROR_CODE_BUSINESS_LIMITED = 'business_limited';
export const ERROR_CODE_USAGE_LIMITED = 'usage_limited';

export const ERROR_CODE_EMAIL_BINDED_WITH_OTHER_ACCOUNT = 'email_binded_other_account';
export const ERROR_CODE_EMAIL_VERIFICATION_WRONG_ID = 'email_verificaiton_wrong_id';
export const ERROR_CODE_EMAIL_VERIFICATION_EXPIRED = 'email_verificaiton_expired';
export const ERROR_CODE_EMAIL_ALREADY_VERIFIED = 'email_already_verified';
export const ERROR_CODE_PHONE_BINDED_WITH_OTHER_ACCOUNT = 'phone_binded_other_account';
export const ERROR_CODE_INVLAID_PHONE_NUMBER = 'invalid_phone_number';
export const ERROR_CODE_SMS_DAILY_LIMIT_REACH = 'sms_daily_limit_reach';
export const ERROR_CODE_SMS_SEND_INTERVAL_LIMTED = 'sms_send_interval_limited';
export const ERROR_CODE_SMS_VERIFICAITON_FAILED_RETRY = 'sms_verificaiton_failed_retry';
export const ERROR_CODE_SMS_VERIFICAITON_FAILED = 'sms_verificaiton_failed';
export const ERROR_CODE_SMS_VERIFICAITON_EXPIRED = 'sms_verificaiton_expired';

export const ERROR_CODE_INVALID_IMAGE_DATA = 'invalid_image_data';
export const ERROR_CODE_ZONE_ID_ALREADY_IN_USE = 'zone_id_already_in_use';
export const ERROR_CODE_DUPLICA_ZONE_TIME_SETTING = 'duplicate_zone_time_setting';