import { getAuth, signInWithCustomToken } from '@firebase/auth';
import { Alert, Form, Input, message, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ADMIN_OPERATION_SEND_VERIFICATION_EMAIL } from '../constants/admin';
import isValidEmail from '../utils/isValidEmail';
import EmailStatusBanner from './EmailStatusBanner';
import OperationDialog from './OperationDialog';

export default ({ visible, onCancel }) => {
  const [newEmail, setNewEmail] = useState('');
  const [successDoc, setSuccessDoc] = useState();
  const intl = useIntl();

  useEffect(() => {
    if (visible) {
      setNewEmail('');
      setSuccessDoc(null);
    }
  }, [visible]);

  const onChange = () => {
    if (!newEmail || !isValidEmail(`${newEmail}`.trim())) {
      return message.error(intl.formatMessage({ defaultMessage: 'Please enter a valid email address' }));
    }

    return {
      type: ADMIN_OPERATION_SEND_VERIFICATION_EMAIL,
      newEmail
    };
  };

  return (
    <OperationDialog title={<FormattedMessage defaultMessage='Change email address' />} visible={visible} onCancel={onCancel}
      onOk={onChange} maskClosable={successDoc ? false : true} footer={successDoc ? false : undefined}
      onSuccess={successDoc => {
        setSuccessDoc(successDoc);
        if (successDoc.get('signInToken')) {
          // Verification succeed
          signInWithCustomToken(getAuth(), successDoc.get('signInToken'))
            .then(() => {
              onCancel();
              Modal.success({
                title: intl.formatMessage({ defaultMessage: 'Your eamil address has been changed to {address}' }, { address: successDoc.get('email') })
              })
            })
            .catch(err => message.error(err.message));
        }
      }}>
      <Form layout='vertical'>
        <Form.Item label={<FormattedMessage defaultMessage='New email address' />}>
          <Input placeholder={intl.formatMessage({ defaultMessage: 'e.g. example@address.com' })} type='email' value={newEmail} onChange={e => setNewEmail(e.target.value)} />
        </Form.Item>
      </Form>
      {successDoc &&
        <Space direction='vertical' className='full-wdith'>
          <Alert banner type='info' message={<FormattedMessage defaultMessage='A verification email has been sent to <b>{address}</b>, please check your inbox complete the verification, <b>Please do not close this dialog, your authentication status will be auto updated once the verification succeed</b>'
            values={{ address: successDoc.get('email'), b: t => <b>{t}</b> }} />} />
          <EmailStatusBanner emailId={successDoc.get('emailId')} />
        </Space>
      }
    </OperationDialog>
  );
}