import { Avatar } from 'antd';
import React from 'react';
import { useRealmId } from '../contexts/AuthenticationContext';
import { useImageHost } from '../contexts/InstallationContext';

export default ({ size, service, shape }) => {
  const realmId = useRealmId();
  const imageHost = useImageHost();
  const photoId = service?.photoId || `${realmId}${service?.id}`;

  return <Avatar size={size} shape={shape} src={`${imageHost}/${photoId}`} />;
}