export const PERMISSION_DATA_NEW_ORDER = 'new_order';
export const PERMISSION_DATA_NEW_ONLINE_ORDER = 'new_online_order';
export const PERMISSION_DATA_NEW_PLANT_ORDER = 'new_plant_order';
export const PERMISSION_DATA_EDIT_ORDER = 'data_edit_general';
export const PERMISSION_DATA_CANCEL_ORDER = 'data_cancel_order';
export const PERMISSION_DATA_DELETE_PHOTO = 'data_delete_photo';
export const PERMISSION_DATA_PAYMENT = 'data_edit_monetary';
export const PERMISSION_DATA_REFUND = 'data_refund';
export const PERMISSION_DATA_EDIT_CUSTOMER = 'data_customer';
export const PERMISSION_DATA_EDIT_CUSTOMER_LOYALITY = 'data_customer_loyalty';
export const PERMISSION_DATA_DELETE_CUSTOMER = 'data_delete';
export const PERMISSION_DATA_SALES = 'data_sales';
export const PERMISSION_DATA_ORDERS = 'data_orders';
export const PERMISSION_DATA_SEARCH_ORDERS = 'data_search_orders';
export const PERMISSION_DATA_EXPORT_ORDERS = 'data_export_orders';
export const PERMISSION_DATA_CUSTOMERS = 'data_customrs';
export const PERMISSION_DATA_SEARCH_CUSTOMERS = 'data_search_customrs';
export const PERMISSION_DATA_ANALYSIS = 'data_analysis';
export const PERMISSION_DATA_EXPORT_CUSTOMERS = 'data_export_customrs';
export const PERMISSION_DATA_EXPORT_REPORTS = 'data_export_exports';
export const PERMISSION_DATA_POS = 'data_pos';

export const PERMISSION_SETTING_BUSINESS = 'setting_business';
export const PERMISSION_SETTING_STORE = 'setting_store';
export const PERMISSION_SETTING_APP = 'setting_app';
export const PERMISSION_SETTING_OPERATION = 'setting_operation';
export const PERMISSION_SETTING_PLANT = 'setting_plant';
export const PERMISSION_SETTING_SYSTEM = 'setting_system';
export const PERMISSION_SETTING_NOTIICATION_CENTER = 'setting_notification_center';
export const PERMISSION_SETTING_WORKFLOW = 'setting_teammate';
export const PERMISSION_SETTING_BILLING = 'setting_billing';

export const PERMISSION_WORKFLOW_JOB_SCHEDULE = 'worklow_job_schedule';
export const PERMISSION_WORKFLOW_JOB_FULFILL = 'worklow_job_fulfill';
export const PERMISSION_WORKFLOW_PRODUCTION = 'worklow_production';
export const PERMISSION_WORKFLOW_LIVE_CHAT = 'worklow_live_chat';
export const PERMISSION_WORKFLOW_POS = 'worklow_pos';
export const PERMISSION_WORKFLOW_PLANT_BILLING = 'worklow_plant_billing';
export const PERMISSION_WORKFLOW_REVIEWS = 'worklow_reviews';
export const PERMISSION_WORKFLOW_PRODUCTION_MONITOR = 'production_monitor';
export const PERMISSION_WORKFLOW_CASE_REPORTS = 'worklow_case_reports';

export const PERMISSION_LOYALTY_PROGRAMS = 'loyalty_programs';
export const PERMISSION_MARKETING = 'marketing';
export const PERMISSION_REPORTS = 'reports';