import React, { createContext, useEffect, useState } from 'react';
import OperationMonitor from '../components/OperationMonitor';
import { ADMIN_OPERATION_BOOT_INTERCOM } from '../constants/admin';
import { useAdmin, useIsEBAccess, useRealmId } from './AuthenticationContext';
import { useOperation } from './InstallationContext';

const IntercomContext = createContext();

export function IntercomProvider({ children }) {
  const admin = useAdmin();
  const realmId = useRealmId();
  const ebAccess = useIsEBAccess();
  const [operationRef, setOperationRef] = useState();
  const runOperation = useOperation();

  useEffect(() => {
    if (realmId && admin && !ebAccess) {
      setOperationRef(runOperation({
        type: ADMIN_OPERATION_BOOT_INTERCOM,
      }));
    }
  }, [realmId, ebAccess, admin?.id]);

  return (
    <IntercomContext.Provider value={null}>
      {children}
      <OperationMonitor operationRef={operationRef} onComplete={successDoc => {
        setOperationRef(null);
        if (successDoc?.get('intercom')) {
          window.Intercom("boot", successDoc.get('intercom'));
        }
      }} />
    </IntercomContext.Provider>
  );
}