import { Button, Modal, Popconfirm } from 'antd';
import React, { useState } from 'react';
import { useOperation } from '../contexts/InstallationContext';
import OperationMonitor from './OperationMonitor';

export default ({ hidden, size, icon, onClick, block, type, danger, children, onSuccess, confirmTitle }) => {
  const [operationRef, setOperationRef] = useState();
  const runOperation = useOperation();

  const onAction = () => {
    const result = onClick();
  
    if (result) {
      if (typeof result.onOk === 'function') {
        Modal.confirm({
          title: result.title,
          content: result.content,
          okText: result.okText,
          okType: result.okType || (danger ? 'danger' : 'primary'),
          onOk: () => {
            setOperationRef(runOperation(result.onOk()));
          }
        });
      } else {
        setOperationRef(runOperation(result));
      }
    }
  };

  return (
    <>
      {confirmTitle ?
        <Popconfirm title={confirmTitle} onConfirm={onAction}>
          <Button hidden={hidden} size={size} icon={icon} block={block} type={type} danger={danger} loading={Boolean(operationRef)}>{children}</Button>
        </Popconfirm>
        :
        <Button hidden={hidden} size={size} icon={icon} onClick={onAction} block={block} danger={danger} type={type} loading={Boolean(operationRef)}>{children}</Button>
      }
      <OperationMonitor operationRef={operationRef} onComplete={successDoc => {
        setOperationRef(null);
        if (successDoc && onSuccess) {
          onSuccess(successDoc);
        }
      }} />
    </>
  );
}